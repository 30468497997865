<template>
  <div class="container">
    <div class="banner">
      <Header></Header>
      <div class="title">
        <div>景翔云体系 <br> 为您的体系保驾护航</div>
        <div>Welcome to login the Jeamswan system  escort your system </div>
        <div class="btn" @click="skip">申请免费使用</div>
      </div>
    </div>
    <div class="module">
      <div class="module-box" v-for="(item,index) in module" :key="index" @click="link">
        <div>
          <img :src="item.img" alt="">
        </div>
        <span>{{item.text}}</span>
      </div>
      <div class="module-box">
        <div class="omit">
          <img src="../../assets/mobileImgs/module6.png" alt="">
        </div>
        <span> </span>
      </div>
    </div>
    <div class="function-box">
      <div class="title">
        <div>云体系能做什么</div>
        <img src="../../assets/mobileImgs/function1.png" alt="">
      </div>
      <div class="function-content">
        <div class="function-item" v-for="(item,index) in functionList" :key="index">
          <div class="item-top">
            <img :src="item.img" alt="">
          </div>
          <div class="item-bottom">{{item.text}}</div>
        </div>
      </div>
    </div>
    <div class="platform">
      <div class="platform-top">
        <div class="title">
          <div>多生态多场景的开发平台</div>
          <img src="../../assets/mobileImgs/platform1.png" alt="">
        </div>
        <div class="platform-content">
          <div class="platform-item" v-for="(item,index) in platform" :key="index">
            <img :src="item.img" alt="">
            <div>{{item.text}}</div>
          </div>
        </div>
      </div>
      <div class="platform-bottom">
        <img src="../../assets/mobileImgs/banner.png" alt="">
        <div>采用清晰、易懂的界面，所有功能都能一目了然，<br> 使功能界面非常人性、友好</div>
      </div>
    </div>
    <div class="experience">
      <div class="title">立即开启您的云体系管理</div>
      <div class="btn">立即申请免费体验</div>
    </div>
    <div class="book">
      <div class="title">
        <div>景翔书籍</div>
        <img src="../../assets/mobileImgs/platform1.png" alt="">
      </div>
      <div class="book-content">
        <div class="book-item" v-for="(item,index) in book" :key="index">
          <img :src="item.img" alt="">
          <div>
            <span>{{item.title}}</span>
            <p>
              {{item.text}}
            </p>
          </div>
        </div>
        <div class="btn">查看更多</div>
      </div>
    </div>
    <div class="partner">
      <div class="title">
        <div>我们的伙伴</div>
        <img src="../../assets/mobileImgs/platform1.png" alt="">
      </div>
      <div class="partner-content">
        <div class="partner-item" v-for="(item,index) in partner" :key="index">
          <img :src="item.img" alt="">
        </div>
      </div>
    </div>
    <div class="contact">
      <div class="accounts">
        <div class="phone">
          <img src="../../assets/mobileImgs/phone1.png" alt="">
          <span>400-1231-0852</span>
        </div>
        <div class="accounts-content">
          <div class="accounts-item" v-for="(item,index) in accounts" :key="index">
            <img :src="item.img" alt="">
            <div>{{item.text}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../../components/mobile/Header.vue';
export default {
  components:{
    Header
  },
  data(){
    return{
      module:[
        {
          img:require("../../assets/mobileImgs/module1.png"),
          text:"管理软件"
        },
        {
          img:require("../../assets/mobileImgs/module2.png"),
          text:"体系搭建"
        },
        {
          img:require("../../assets/mobileImgs/module3.png"),
          text:"体系维护"
        },
        {
          img:require("../../assets/mobileImgs/module4.png"),
          text:"体系落地"
        },
        {
          img:require("../../assets/mobileImgs/module5.png"),
          text:"培训课程"
        },
      ],
      functionList:[
        {
          img:require("../../assets/mobileImgs/function2.png"),
          text:"降低体系维护成本"
        },
        {
          img:require("../../assets/mobileImgs/function3.png"),
          text:"为第三方保驾护航"
        },
        {
          img:require("../../assets/mobileImgs/function4.png"),
          text:"体系检查自动化"
        },
        {
          img:require("../../assets/mobileImgs/function5.png"),
          text:"专业顾问团队知道"
        },
        {
          img:require("../../assets/mobileImgs/function6.png"),
          text:"百款课程随意选"
        },
        {
          img:require("../../assets/mobileImgs/function7.png"),
          text:"零成本打造学习平台"
        },
      ],
      platform:[
        {
          img:require("../../assets/mobileImgs/platform2.png"),
          text:"简洁"
        },
        {
          img:require("../../assets/mobileImgs/platform3.png"),
          text:"易用"
        },
        {
          img:require("../../assets/mobileImgs/platform4.png"),
          text:"实用"
        },
        {
          img:require("../../assets/mobileImgs/platform5.png"),
          text:"方便"
        }
      ],
      book:[
        {
          img:require("../../assets/images/book4.png"),
          title:"过程方法",
          text:"要打造一流的企业，就是要打造一流 的过程。本书自始至终都在体现目前 流行的“过程方法”的应用，并融入 多年的实践经验与探索。相信对正要进行流程优化！"
        },
        {
          img:require("../../assets/images/book4.png"),
          title:"过程方法",
          text:"要打造一流的企业，就是要打造一流 的过程。本书自始至终都在体现目前 流行的“过程方法”的应用，并融入 多年的实践经验与探索。相信对正要进行流程优化！"
        },
        {
          img:require("../../assets/images/book4.png"),
          title:"过程方法",
          text:"要打造一流的企业，就是要打造一流 的过程。本书自始至终都在体现目前 流行的“过程方法”的应用，并融入 多年的实践经验与探索。相信对正要进行流程优化！"
        },
      ],
      partner:[
        {
          img:require("../../assets/images/logo/logo1.png"),
        },
        {
          img:require("../../assets/images/logo/logo2.png"),
        },
        {
          img:require("../../assets/images/logo/logo3.png"),
        },
        {
          img:require("../../assets/images/logo/logo4.png"),
        },
        {
          img:require("../../assets/images/logo/logo5.png"),
        },
        {
          img:require("../../assets/images/logo/logo6.png"),
        },
        {
          img:require("../../assets/images/logo/logo7.png"),
        },
        {
          img:require("../../assets/images/logo/logo8.png"),
        },
        {
          img:require("../../assets/images/logo/logo9.png"),
        },
        {
          img:require("../../assets/images/logo/logo10.png"),
        },
        {
          img:require("../../assets/images/logo/logo11.png"),
        },
        {
          img:require("../../assets/images/logo/logo12.png"),
        },
        {
          img:require("../../assets/images/logo/logo13.png"),
        },
        {
          img:require("../../assets/images/logo/logo14.png"),
        },
        {
          img:require("../../assets/images/logo/logo15.png"),
        },
        {
          img:require("../../assets/images/logo/logo16.png"),
        },
        {
          img:require("../../assets/images/logo/logo17.png"),
        },
        {
          img:require("../../assets/images/logo/logo18.png"),
        },
        {
          img:require("../../assets/images/logo/logo19.png"),
        },
        {
          img:require("../../assets/images/logo/logo20.png"),
        },
        {
          img:require("../../assets/images/logo/logo21.png"),
        },
        {
          img:require("../../assets/images/logo/logo22.png"),
        },
      ],
      accounts:[
        {
          img:require("../../assets/mobileImgs/code.jpg"),
          text:"公司公众号"
        },
        {
          img:require("../../assets/mobileImgs/code.jpg"),
          text:"公司公众号"
        },
      ]
    }
  },
  methods:{
    skip(){
      window.location.href = "http://101.33.249.65:8012/";
    },
    link(){
      this.$router.push("/systemConstruction");
    },
  }
}
</script>

<style lang="less" scoped>
.container{
  .banner{
    position: relative;
    background: url("../../assets/mobileImgs/banner.png") no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 9.37rem;
    .title{
      position: absolute;;
      top: 3.7rem;
      width: 100%;
      font-weight: bold;
      text-align: center;
      div:nth-child(1){
        font-size: 0.54rem;
        color: #FFFFFF;
        line-height: 0.6rem;
      }
      div:nth-child(2){
        font-size: 0.16rem;
        color: #C9C9C9;
        line-height: 0.33rem;
        margin-top: .2rem;
      }
      .btn{
        width: 2.74rem;
        height: 0.83rem;
        border: 0.02rem solid #AAB2BA;
        border-radius: 0.08rem;
        font-size: .3rem;
        font-weight: bold;
        color: #ffffff;
        line-height: 0.83rem;
        margin: .44rem auto;
      }
    }
  }
  .module{
    width: 100%;
    height: 6.49rem;
    background: #FFFFFF;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .module-box{
      width: 1.64rem;
      text-align: center;
      margin: 0 .24rem;
      &>div{
        width: 1.64rem;
        height: 1.64rem;
        background: #EBF1FE;
        border: 0.02rem solid #F3F6FD;
        border-radius: 0.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: .25rem;
        img{
          width: 1rem;
          height: 1rem;
        }
      }
      span{
        display: inline-block;
        font-size: 0.28rem;
        font-weight: 500;
        color: #000000;
      }
      .omit{
        background: #ffffff;
        border: none;
        img{
          width: .68rem;
          height: .16rem;
        }
      }
    }
  }
  .function-box{
    width: 100%;
    height: 11.25rem;
    background: #5A98FA;
    padding-top: 1.28rem;
    box-sizing: border-box;
    .title{
      text-align: center;
      margin-bottom: .78rem;
      div{
        font-size: 0.45rem;
        font-weight: bold;
        color: #FFFFFF;
        margin-bottom: .3rem;
      }
      img{
        width: 1.66rem;
        height: 0.08rem;
      }
    }
    .function-content{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .function-item{
        width: 3.39rem;
        height: 2.13rem;
        background: #FFFFFF;
        border-radius: 0px 0px 0.06rem 0.06rem;
        margin: 0 .09rem .34rem;
        .item-top{
          height: 1.52rem;
          background: #EBF1FE;
          border-radius: 0px 0px 0.06rem 0.06rem;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            width: 1.6rem;
            height: 1.44rem;
          }
        }
        .item-bottom{
          height: .61rem;
          font-size: 0.28rem;
          font-weight: bold;
          color: #14181E;
          text-align: center;
          line-height: .61rem;
        }
      }
    }
  }
  .platform{
    width: 100%;
    height: 10.75rem;
    background: #FFFFFF;
    .platform-top{
      height: 4.3rem;
      padding-top: 1.5rem;
      box-sizing: border-box;
      .title{
        text-align: center;
        div{
          height: .43rem;
          line-height: .43rem;
          font-size: 0.45rem;
          font-weight: bold;
          color: #000000;
          margin-bottom: .3rem;
        }
        img{
          width: 1.66rem;
          height: .08rem;
        }
      }
      .platform-content{
        margin-top: .50rem;
        display: flex;
        justify-content: center;
        .platform-item{
          width: .8rem;
          text-align: center;
          margin: 0 .32rem;
          img{
            width: .8rem;
            height: .8rem;
          }
          div{
            font-size: 0.28rem;
            font-weight: 500;
            color: #333333;
            height: .25rem;
            line-height: .25rem;
          }
        }
      }
    }
    .platform-bottom{
      height: 6.45rem;
      background: #EBF1FE;
      border-radius: 0px 0px 0.06rem 0.06rem;
      text-align: center;
      img{
        width: 7.22rem;
        height: 3.65rem;
        margin-top: .74rem;
      }
      div{
        margin-top: .58rem;
        height: 0.61rem;
        font-size: 0.26rem;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .experience{
    background: url("../../assets/mobileImgs/bg.png") no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 3.76rem;
    text-align: center;
    padding-top: 1.35rem;
    box-sizing: border-box;
    .title{
      height: 0.39rem;
      font-size: 0.4rem;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 0.39rem;
      text-shadow: 0px 0.02rem 0.12rem rgba(73,120,186,0.11);
    }
    .btn{
      width: 2.7rem;
      height: 0.67rem;
      background: #F8FAFB;
      border-radius: 0.08rem;
      font-size: 0.28rem;
      font-weight: 400;
      color: #4A7EF1;
      line-height: 0.67rem;
      margin: .36rem auto;
    }
  }
  .book{
    width: 100%;
    height: 13.89rem;
    background: #FFFFFF;
    padding-top: 1.3rem;
    box-sizing: border-box;
    .title{
      text-align: center;
      margin-bottom: .66rem;
      div{
        height: 0.43rem;
        font-size: 0.45rem;
        font-weight: bold;
        color: #000000;
        line-height: 0.43rem;
        margin-bottom: .3rem;
      }
      img{
        width: 1.66rem;
        height: .08rem;
      }
    }
    .book-content{
      padding: 0 .34rem;
      box-sizing: border-box;
      .book-item{
        width: 100%;
        height: 2.92rem;
        background: #FFFFFF;
        border: 1px solid #D0D0D0;
        border-radius: 0.08rem;
        padding: .16rem .26rem .16rem .28rem;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        margin-bottom: .2rem;
        img{
          width: 1.37rem;
          height: 2.59rem;
          margin-right: .45rem;
        }
        div{
          span{
            display: inline-block;
            height: 0.27rem;
            font-size: 0.28rem;
            font-weight: bold;
            color: #010101;
            line-height: 0.27rem;
            margin-top: .3rem;
          }
          p{
            height: 1.47rem;
            font-size: 0.28rem;
            font-weight: 400;
            color: #666666;
            line-height: 0.3rem;
            margin-top: .3rem;
          }
        }
      }
      .btn{
        width: 2.07rem;
        height: 0.67rem;
        background: #4C83F6;
        border-radius: 0.08rem;
        font-size: 0.28rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.67rem;
        text-align: center;
        margin: .45rem auto;
      }
    }
  }
  .partner{
    width: 100%;
    height: 14.5rem;
    background: #EBF1FE;
    padding-top: 1.25rem;
    box-sizing: border-box;
    .title{
      text-align: center;
      margin-bottom: .7rem;
      div{
        height: .43rem;
        font-size: .45rem;
        font-weight: bold;
        color: #000000;
        line-height: .43rem;
        margin-bottom: .3rem;
      }
      img{
        width: 1.66rem;
        height: .08rem;
      }
    }
    .partner-content{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 .34rem;
      .partner-item{
        width: 2.1rem;
        height: 1.25rem;
        background-color: #ffffff;
        margin: 0 .08rem .16rem;
      }
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .contact{
    width: 100%;
    height: 7.05rem;
    background: url("../../assets/mobileImgs/bg1.png") no-repeat;
    background-size: 100% 100%;
    .accounts{
      padding-top: 1.05rem;
      box-sizing: border-box;
      .phone{
        width: 4.22rem;
        height: 0.92rem;
        background: #4C83F6;
        border-radius: 0.05rem;
        margin: 0 auto;
        text-align: center;
        line-height: 0.92rem;
        img{
          width: .42rem;
          height: .5rem;
          vertical-align: middle;
          margin-right: .24rem;
        }
        span{
          height: .32rem;
          font-size: .42rem;
          font-weight: bold;
          color: #FFFFFF;
          vertical-align: middle;
        }
      }
      .accounts-content{
        margin-top: .62rem;
        display: flex;
        justify-content: center;
        .accounts-item{
          margin: 0 .09rem;
          img{
            width: 3.11rem;
            height: 3.11rem;
          }
          div{
            width: 3.11rem;
            height: 0.26rem;
            font-size: 0.28rem;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 0.26rem;
            text-align: center;
            margin-top: .32rem;
          }
        }
      }
    }
  }
}
</style>